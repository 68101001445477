// eslint-disable-next-line import/no-extraneous-dependencies
import { jsPDF } from 'jspdf';
import {
  DetailedLabelRequest,
  OrderForLabelPrint,
  OrderForPrintListItem,
  OrderItem,
  OrderListItem,
  OrderPreview,
  OrderStates,
  PagedResponse,
  RevertedOrderStatus,
  SimpleOrder,
} from '../apiTypes';
import { ApiGroup } from '../model/ApiGroup';

export const mockGetOrdersData: SimpleOrder[] = [
  {
    id: 111,
    partnerId: '111',
    originalId: '4536964122',
    source: 'ALZA.CZ',
    hasLabels: false,
  },
  {
    id: 222,
    partnerId: '222',
    originalId: '4536941441',
    source: 'ALZA.CZ',
    hasLabels: true,
  },
  {
    id: 333,
    partnerId: null,
    originalId: '13575014601',
    source: 'MALL.CZ',
    hasLabels: false,
  },
];

export const mockGetOrderData1: OrderForLabelPrint = {
  id: 111,
  originalId: '4536964122',
  source: 'ALZA.CZ',
  shippingLabelsPrintMethod: 'DETAILED',
  items: [
    {
      id: '60717',
      originalId: '1248827010',
      count: 3,
    },
    {
      id: '242727',
      originalId: '1248798325',
      count: 2,
    },
    {
      id: '149572',
      originalId: '100065766787',
      count: 1,
    },
  ],
  apiGroup: ApiGroup.ALZA,
};

export const mockGetPackagePreviewOrder1: DetailedLabelRequest = {
  numberOfPackages: 2,
  packages: [{
    packageId: 1,
    weight: 20,
    dimension1: 25,
    dimension2: 66,
    dimension3: 33,
    volume: undefined,
    size: undefined,
    items: [{
      id: '60717',
      count: 3,
    }],
  },
  {
    packageId: 2,
    weight: 15,
    dimension1: 2055,
    dimension2: 66,
    dimension3: 333,
    volume: undefined,
    size: undefined,
    items: [
      {
        id: '242727',
        count: 2,
      },
      {
        id: '149572',
        count: 1,
      }],
  },
  {
    packageId: 3,
    weight: 5,
    dimension1: 255,
    dimension2: 636,
    dimension3: 33,
    volume: undefined,
    size: undefined,
    items: [
      {
        id: '60717',
        count: 0,
      }],
  }],
};

export const mockGetOrderData2: OrderForLabelPrint = {
  id: 222,
  originalId: '4536941441',
  source: 'ALZA.CZ',
  shippingLabelsPrintMethod: 'DETAILED',
  items: [
    {
      id: '242727',
      originalId: '1248798325',
      count: 1,
    },
  ],
  apiGroup: ApiGroup.ALZA,
};

export const mockGetOrderData3: OrderForLabelPrint = {
  id: 333,
  originalId: '13575014601',
  source: 'MALL.CZ',
  shippingLabelsPrintMethod: 'SIMPLE',
  items: [
    {
      id: '149572',
      originalId: '100065766787',
      count: 1,
    },
  ],
  apiGroup: ApiGroup.MALL_GROUP,
};

// eslint-disable-next-line new-cap
const jsPdf = new jsPDF();
jsPdf.text('Dummy', 10, 10);
export const dummyPdf = jsPdf.output('blob');

export const orderListItemTemplate :OrderListItem = {
  id: 12346,
  marketplace: 'MALL.CZ',
  apiGroup: ApiGroup.MALL_GROUP,
  partnerId: '1',
  orderNumber: '126486461',
  orderValue: 1025.58,
  orderValueCurrency: 'CZK',
  created: '2022-05-05',
  status: OrderStates.SHIPPING,
  statusDate: '2022-05-05',
  carrier: 'TopTrans',
  managedByPartner: false,
  suggestedExpedition: '2022-05-05',
  codValue: 10,
  codValueCurrency: 'CZK',
  packagesCount: 3,
  trackingNumber: '4545-AD4545454',
  shippingLabelUrl: 'https://salesconnect-test.s3.eu-central-1.amazonaws.com/pdfko.pdf',
  shippingListUrl: 'https://salesconnect-test.s3.eu-central-1.amazonaws.com/pdfko.pdf',
  updateStatus: 'UPDATE_PENDING',
  updateStatusResponseError: null,
  allowedStates: [OrderStates.SHIPPING, OrderStates.CANCELLED],
  crossDockDelivery: false,
};

const orderListItems: OrderListItem[] = [];
for (let i = 0; i < 25; i++) {
  orderListItems.push({
    ...orderListItemTemplate,
    id: i,
    // eslint-disable-next-line no-nested-ternary
    apiGroup: i % 6 === 0 ? (i % 12 === 0 ? ApiGroup.ALZA : ApiGroup.ALLEGRO) : ApiGroup.MALL_GROUP,
    orderNumber: `ABC${i}125${i * 13}`,
    shippingLabelUrl: i % 2 === 0 ? '' : orderListItemTemplate.shippingLabelUrl,
    shippingListUrl: i % 2 === 0 ? '' : orderListItemTemplate.shippingListUrl,
    updateStatus: i % 3 === 0 ? 'UPDATE_PENDING' : 'UP_TO_DATE',
    updateStatusResponseError: i % 3 === 0 ? 'failed to update in MP due to blablabla' : null,
    labelsError: i % 10 === 0 ? 'Error: Something failed' : undefined,
    numberOfCrossDockTrackings: i % 10 === 0 ? 2 : undefined,
    crossDockDelivery: i % 3 === 0,
  });
}

export const mockGetOrdersLists: PagedResponse<OrderListItem> = {
  page: 1,
  total: 1234,
  pageSize: 25,
  data: orderListItems,
};

export const mockGetOrderPreview : OrderPreview = {
  products: [
    {
      orderProductCode: '1057116253',
      title: 'Altán se střechou polyratan 400 x 400 x 200 cm hnědý a krémový',
      price: 12231.50,
      quantity: 1,
      currency: 'CZK',
      vat: 21,
    },
  ],
  customer: {
    name: 'Franta Nakupovač',
    company: '',
    phone: '+420123456789',
    email: 'franta@codingbear.com',
    address1: 'Krátká 123',
    address2: '',
    city: 'Praha',
    zip: '10000',
  },
  parcelTracking: {
    customer: [
      '1901000696172',
    ],
    crossDock: [],
  },
};

export const mockOrdersExport = '"Suggested expedition date","Open date","Status","Marketplace","Order ID (marketplace)","Order ID (partner)","SKU","EAN","Title","Unit price","Quantity","Total unit price","Delivery price","CoD price","Total order value","Currency","Carrier","CoD value","Delivery type","Customer name","Customer email","Customer phone","Customer address","Country","Shipping label URL"\n'
    + ',"2021-05-23 14:41:46","SHIPPED","MALL.CZ","4402025941","286634570","145899","8719883794594","Zatahovací markýza 400 x 150 cm oranžovo-hnědá","1946,5","1","1946,5",,,"2290","CZK","ULOZENKA","0","Branch","Real Person","mail@mail.com","+1234567891","Ulice 123, 11111 Praha","CZ",\n'
    + ',"2021-05-28 10:23:50","SHIPPED","MALL.PL","4404200352","288471605","92434","8719883860152","Nafukovací polštáře pod zimní plachtu pro nadzemní bazény 2 ks","806,65","1","806,65",,,"949","CZK","DPD","0","Branch","Real Person","mail@mail.com","+1234567891","Ulice 123, 11111 Praha","CZ",\n'
    + ',"2021-05-30 13:37:50","SHIPPED","MALL.CZ","4404966722","288991060","42315","8718475975656","Balkónová zástěna HDPE 90x600 cm antracitová","926,5","1","926,5",,,"1090","CZK","ZASILKOVNA","0","Branch","Real Person","mail@mail.com","+1234567891","Ulice 123, 11111 Praha","CZ",\n';

export const orderForPrintListItemTemplate :OrderForPrintListItem = {
  id: 12346,
  marketplace: 'MALL.CZ',
  apiGroup: 'MALL_GROUP',
  orderNumber: '126486461',
  partnerId: 'ab458743',
  managedByPartner: false,
  suggestedExpedition: '2022-05-05 12:55',
  carrier: 'TopTrans',
  pagesCount: 2,
  crossDockDelivery: false,
};

const orderForPrintListItems: OrderForPrintListItem[] = [];
for (let i = 0; i < 25; i++) {
  orderForPrintListItems.push({
    ...orderForPrintListItemTemplate,
    id: i,
    orderNumber: `ABC${i}125${i * 13}`,
    partnerId: `Partner_${i}125${i * 13}`,
    crossDockDelivery: i % 3 === 0,
  });
}

export const mockGetOrdersForPrintLists: PagedResponse<OrderForPrintListItem> = {
  page: 1,
  total: 1234,
  pageSize: 25,
  data: orderForPrintListItems,
};

export const mockGetOrderStatusReverted: RevertedOrderStatus = {
  orderId: 1,
  status: OrderStates.OPEN,
  allowedStatusChanges: [OrderStates.SHIPPING, OrderStates.CANCELLED],
};

export const mockGetOrderItems: OrderItem[] = [
  {
    id: 1,
    partnerProductCode: '1111',
    ean: 'EAN1111',
    orderProductCode: '#1111',
    title: 'Whirlpool 1111',
    serialNumbers: [
      {
        id: 1,
        orderLineId: 1,
        serialNumber: 'SN1111',
      },
      {
        id: 2,
        orderLineId: 1,
        serialNumber: 'SN1111',
      },
    ],
  },
  {
    id: 2,
    partnerProductCode: '2222',
    ean: 'EAN2222',
    orderProductCode: '#2222',
    title: 'Whirlpool 2222',
    serialNumbers: [
      {
        id: 1,
        orderLineId: 2,
      },
    ],
  },
  {
    id: 3,
    partnerProductCode: '3333',
    ean: 'EAN3333',
    orderProductCode: '#3333',
    title: 'Whirlpool 3333',
    serialNumbers: [],
  },
];
